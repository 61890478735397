import { ConnectedRouter } from "connected-react-router";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { history } from "store/store";

import { LoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import { useSelector, useStore } from "react-redux";
import {
  selectWidgetMaximizedAtLeastOnce,
  selectWidgetMaximizedState,
} from "store/modules/app-state/app-state.selectors";
import { useDebouncedValue } from "utils/use-debounced-value";
import { isInIframe } from "utils/is-in-iframe";
import { AppCompact } from "app-compact";
import { useSimpleState } from "@sdk/utils/use-simple-state";
import {
  selectActivatedCheckLists,
  selectContact,
  selectSession,
  selectWidget,
} from "store/modules/session/session.selectors";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import { useLocalStorageStore } from "utils/use-local-storage-store";
import { selectNewsfeedItemsByType } from "store/modules/newsfeed-items/newsfeed-items.selectors";
import { loadAllNewsfeedItemsByTypeId } from "store/modules/newsfeed-items/newsfeed-items.helpers";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";

const AppRootRouterInner = React.lazy(() => import("./app-router-inner"));

export interface iCompactViewState {
  view: "NONE" | "CHECK_LIST" | "NEWSFEED";
  checkListId?: string;
  newsfeedItemId?: string;
}

const _AppRootRouter = () => {
  const _isWidgetMaximized = useSelector(selectWidgetMaximizedState);
  const isWidgetMaximizedAtLeastOnce = useSelector(
    selectWidgetMaximizedAtLeastOnce
  );

  const [isWidgetMaximized] = useDebouncedValue(_isWidgetMaximized, 200);

  const _isInIframe = isInIframe();

  const [compactViewState, setCompactViewState] = useState({
    view: "NONE",
  } as iCompactViewState);

  useEffect(() => {
    if (_isWidgetMaximized) {
      setCompactViewState({ view: "NONE", checkListId: undefined });
    }
  }, [_isWidgetMaximized]);

  const store = useStore();

  const { doAction: activateCheckList } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (req) => SDK.checkLists.activateChecklist(req),
      // successMessage: "Form has been submitted",
      failureMessage: "Something went wrong",
      defaultResponse: false,
    }),
    []
  );

  useEffect(() => {
    const showCheckListEventListener = () => {
      const allCheckLists = selectActivatedCheckLists(store.getState());
      const activeCheckList = (allCheckLists || []).find(
        (e) => !e.isFullyCompleted
      );
      if (activeCheckList) {
        setCompactViewState({
          view: "CHECK_LIST",
          checkListId: activeCheckList.id!,
        });
      }
    };

    const activateCheckListListener = (data: { checkListId: string }) => {
      const allCheckLists = selectActivatedCheckLists(store.getState());
      const activeCheckList = (allCheckLists || []).find(
        (e) => e.checkListId === data.checkListId
      );
      if (activeCheckList) {
        setCompactViewState({
          view: "CHECK_LIST",
          checkListId: activeCheckList.id!,
        });
      } else {
        activateCheckList(data).then((d) => {
          setCompactViewState({
            view: "CHECK_LIST",
            checkListId: d?.id!,
          });
        });
      }
    };
    GlobalEventEmitter.addListener(
      "SHOW_ACTIVE_CHECKLIST",
      showCheckListEventListener
    );
    GlobalEventEmitter.addListener(
      "ACTIVATE_CHECK_LIST",
      activateCheckListListener
    );
    return () => {
      GlobalEventEmitter.removeListener(
        "SHOW_ACTIVE_CHECKLIST",
        showCheckListEventListener
      );
      GlobalEventEmitter.removeListener(
        "ACTIVATE_CHECK_LIST",
        activateCheckListListener
      );
    };
  }, [activateCheckList, store]);

  // Process Newsfeed
  const widget = useSelector(selectWidget);
  const session = useSelector(selectSession);
  const contact = useSelector(selectContact);
  const [
    _lastShownNewsfeedTimestamp,
    setLastShownNewsfeedTimestamp,
  ] = useLocalStorageStore(`CC_LAST_SEEN_NEWS_${widget?.id!}`, "");

  const showLatestNewsfeed = useCallback(async () => {
    const selectedNewsfeedId =
      widget?.configurations?.newsfeedConfig?.newsfeedTypeId;

    // console.log("showLatestNewsfeed", selectedNewsfeedId);
    let loadedNewsfeedItems = selectNewsfeedItemsByType(selectedNewsfeedId!)(
      store.getState()
    );
    if (!loadedNewsfeedItems || loadedNewsfeedItems.length === 0) {
      await loadAllNewsfeedItemsByTypeId(selectedNewsfeedId!)(store);
    }
    loadedNewsfeedItems = selectNewsfeedItemsByType(selectedNewsfeedId!)(
      store.getState()
    );
    const latestNewsfeed = loadedNewsfeedItems[0];
    if (latestNewsfeed) {
      setCompactViewState({
        view: "NEWSFEED",
        newsfeedItemId: latestNewsfeed.id,
      });
    }
  }, [store, widget?.configurations?.newsfeedConfig?.newsfeedTypeId]);

  useEffect(() => {
    if (!widget?.configurations?.newsfeedConfig?.enabled) {
      return;
    }
    const lastPublishedTimestamp =
      widget?.configurations?.newsfeedConfig?.lastPublishedTimestamp;
    const lastShownNewsfeedTimestamp = Number(_lastShownNewsfeedTimestamp);

    const contactCreatedAt = contact?.metaData?.createdTime;

    const sessionCreatedAt = session?.metaData?.createdTime;

    // console.log("lastPublishedTimestamp", lastPublishedTimestamp);
    // console.log("lastShownNewsfeedTimestamp", lastShownNewsfeedTimestamp);
    // console.log("contactCreatedAt", contactCreatedAt);

    if (
      lastPublishedTimestamp &&
      lastPublishedTimestamp > lastShownNewsfeedTimestamp &&
      lastPublishedTimestamp + 7 * 24 * 60 * 60 * 1000 > Date.now()
    ) {
      // Recent New Published Newsfeed Available
      if (contactCreatedAt && contactCreatedAt < lastPublishedTimestamp) {
        // Contact is before newsfeed is published
        // Show newsfeed
        showLatestNewsfeed().catch((e) => {
          // Ignore
        });
      }
    }
  }, [
    _lastShownNewsfeedTimestamp,
    contact?.metaData?.createdTime,
    session?.metaData?.createdTime,
    showLatestNewsfeed,
    store,
    widget?.configurations?.newsfeedConfig?.enabled,
    widget?.configurations?.newsfeedConfig?.lastPublishedTimestamp,
  ]);

  if (_isInIframe && !isWidgetMaximized && compactViewState.view !== "NONE") {
    return (
      <AppCompact
        compactViewState={compactViewState}
        setCompactViewState={setCompactViewState}
      />
    );
  }

  if (!isWidgetMaximizedAtLeastOnce && !isWidgetMaximized && _isInIframe) {
    return (
      <div className="h-full w-full flex flex-row justify-center items-center">
        <LoadingIndicator size={48} />
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="h-full w-full flex flex-row justify-center items-center">
          <LoadingIndicator size={48} />
        </div>
      }
    >
      <AppRootRouterInner />
    </Suspense>
  );
};

const AppRootRouter = () => {
  return (
    <ConnectedRouter history={history}>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <_AppRootRouter />
    </ConnectedRouter>
  );
};

export default AppRootRouter;
