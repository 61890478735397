import { EntityServices } from "@sdk/utils/entity.service";
import { iArticle, iArticleCollection } from "./knowledge-base-models";

import { axios, AxiosResponse } from "../axios";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";

export class KnowledgeBaseService {
  getCurrentConfig = () => {
    return this.config;
  };

  constructor(protected config: { basePath: string; token: string }) {}

  /* -------------------------------- Connection -------------------------------- */

  articles = {
    query: async (req: { query: any; options: PaginatedOptions }) => {
      const results: AxiosResponse<
        PaginatedResults<iArticle>
      > = await axios.post(
        this.config.basePath + `/articles/public/query`,
        req,
        {
          headers: {
            authorization: this.config.token,
          },
        }
      );
      return results.data;
    },
    getById: async (id: string) => {
      const results: AxiosResponse<iArticle> = await axios.get(
        this.config.basePath + `/articles/public/${id}`,
        {
          headers: {
            authorization: this.config.token,
          },
        }
      );
      return results.data;
    },
    search: async (
      searchQuery: string,
      organizationId: string,
      portalId: string
    ) => {
      const results: AxiosResponse<iArticle[]> = await axios.post(
        this.config.basePath + `/articles/public/search`,
        { query: searchQuery, useEmbeddings: false },
        {
          headers: {
            authorization: this.config.token,
          },
          params: {
            organizationId,
            portalId
          },
        }
      );
      return results.data;
    },
    askAI: async (query: string, organizationId: string, portalId: string) => {
      const results: AxiosResponse<{ response?: string }> = await axios.post(
        this.config.basePath + `/articles/public/ask-ai`,
        { query },
        {
          headers: {
            authorization: this.config.token,
          },
          params: {
            organizationId,
            portalId,
          },
        }
      );
      return results.data;
    },
    rateArticle: async (
      articleId: string,
      req: { rating: number; feedback?: string }
    ) => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + `/articles/public/${articleId}/rate-article`,
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        }
      );
      return results.data;
    },
    logArticleView: async (articleId: string) => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + `/articles/public/${articleId}/view-log`,
        {},
        {
          headers: {
            Authorization: this.config.token,
          },
        }
      );
      return results.data;
    },
  };

  collections = {
    query: async (req: { query: any; options: PaginatedOptions }) => {
      const results: AxiosResponse<
        PaginatedResults<iArticleCollection>
      > = await axios.post(
        this.config.basePath + `/article-collections/public/query`,
        req,
        {
          headers: {
            authorization: this.config.token,
          },
        }
      );
      return results.data;
    },
    getById: async (id: string) => {
      const results: AxiosResponse<iArticleCollection> = await axios.get(
        this.config.basePath + `/article-collections/public/${id}`,
        {
          headers: {
            authorization: this.config.token,
          },
        }
      );
      return results.data;
    },
  };
}
