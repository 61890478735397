import { SocketConnector } from "@sdk/@libs/socket-connector";
import {
  iMessage,
  iSessionPublicData,
  iWidget,
  MessageAttachmentTypes,
} from "@sdk/services/models";
import {
  AudioNotificationsService,
  AvailableAudios,
} from "@services/audio-notifications-service";
import { push } from "connected-react-router";
import { extractContactDataFromDataObj } from "helpers/extract-contact-data-from-data-obj";
import { initLocalConversation } from "helpers/init-local-conversation";
import { iLocalConversationMessage } from "helpers/local-conversation-generator";
import { ResetSessionAndReload } from "helpers/reset-session-and-reload";
import find from "lodash/find";
import {
  setPreferredSubjectLine,
  setPreInitConfig,
  setWidgetMaximizedState,
} from "store/modules/app-state/app-state.actions";
import { CampaignsController } from "store/modules/app-state/campaigns.controller";
import { GenerateSimpleCampaign } from "store/modules/app-state/helpers/generate-simple-campaign";
import { loadAllConversations } from "store/modules/conversations/conversations.helpers";
import { selectConversationsQuery } from "store/modules/conversations/conversations.selectors";
import {
  patchWidget,
  patchWidgetColor,
  setSession,
  setWidget,
} from "store/modules/session/session.actions";
import {
  selectActivatedCheckLists,
  selectSession,
  selectWidget,
} from "store/modules/session/session.selectors";

import { store } from "store/store";
import { SetLocalStorageItemSafely } from "utils/set-local-storage-item";
import { SDK } from "../@sdk";
import { stringToJson } from "../utils/string-to-json";
import { iPreInitConfig } from "store/modules/app-state/app-state.model";
import { getRandomUser, getUserById } from "store/modules/users/users.helpers";
import {
  selectWidgetMaximizedAtLeastOnce,
  selectWidgetMaximizedState,
} from "store/modules/app-state/app-state.selectors";
import { sesSessionInitialData } from "store/modules/session/session.helpers";
import { NotifyUnreadMessages } from "helpers/notify-unread-messages";
import { loadAllTickets } from "store/modules/tickets/tickets.helpers";
import { setConversationsQueryResults } from "store/modules/conversations/conversations.slice";
import { GlobalEventEmitter } from "utils/global-event-emitter";

export const processLastIdentifiedUser = (
  widgetId: string,
  session: iSessionPublicData
) => {
  try {
    const lastRecord = window.localStorage.getItem(`${widgetId}-last-user-id`);
    if (lastRecord) {
      const parsedRecord = JSON.parse(lastRecord);
      if (parsedRecord.timestamp + 7000 > Date.now()) {
        // Process Recent Identification
        identifyUser(parsedRecord.data, widgetId, session);
      }
    }
  } catch (e) {
    // Ignore Error
  }
};

const identifyUser = (
  userData: {
    id?: string;
    firstName?: string;
    lastName?: string;
    primaryEmail?: string;
    primaryMobile?: string;
    company?: {
      id?: string;
      name: string;
    };
  },
  widgetId?: string,
  session?: iSessionPublicData
) => {
  if (!session || !widgetId) {
    SetLocalStorageItemSafely(
      `${widgetId}-last-user-id`,
      JSON.stringify({
        timestamp: Date.now(),
        data: userData,
      })
    );
    return;
  }
  const fieldsToUpdate: typeof userData = {};
  if (
    (userData.id && !session?.metaData.customUserId) ||
    (userData.id && userData.id !== session?.metaData.customUserId)
  ) {
    fieldsToUpdate.id = userData.id;
  }
  // Todo: Here itself we can clear the session and reload without waiting for backend to tell that ID is different

  if (
    (userData.firstName && !session?.contact?.data?.firstName) ||
    (userData.firstName &&
      userData.firstName !== session?.contact?.data?.firstName)
  ) {
    fieldsToUpdate.firstName = userData.firstName;
  }

  if (
    (userData.lastName && !session?.contact?.data?.lastName) ||
    (userData.lastName &&
      userData.lastName !== session?.contact?.data?.lastName)
  ) {
    fieldsToUpdate.lastName = userData.lastName;
  }

  if (
    (userData.primaryEmail && !session?.contact?.data?.primaryEmail) ||
    (userData.primaryEmail &&
      userData.primaryEmail !== session?.contact?.data?.primaryEmail)
  ) {
    fieldsToUpdate.primaryEmail = userData.primaryEmail;
  }

  if (
    (userData.primaryMobile && !session?.contact?.data?.primaryMobile) ||
    (userData.primaryMobile &&
      userData.primaryMobile !== session?.contact?.data?.primaryMobile)
  ) {
    fieldsToUpdate.primaryMobile = userData.primaryMobile;
  }

  if (
    (userData?.primaryEmail &&
      session?.contact?.data?.primaryEmail &&
      session?.contact?.data?.primaryEmail !== userData.primaryEmail) ||
    (userData?.primaryMobile &&
      session?.contact?.data?.primaryMobile &&
      session?.contact?.data?.primaryMobile !== userData.primaryMobile)
  ) {
    SetLocalStorageItemSafely(
      `${widgetId}-last-user-id`,
      JSON.stringify({
        timestamp: Date.now(),
        data: userData,
      })
    );
    ResetSessionAndReload(store);
    return;
  }

  const existingSessionVerification = session.metaData.isVerified;
  if (
    Object.keys(fieldsToUpdate).length > 0 ||
    !session?.metaData?.isVerified
  ) {
    SDK.identifyUser(fieldsToUpdate)
      .then((sessionIdentificationResponse) => {
        if (
          sessionIdentificationResponse.changed &&
          sessionIdentificationResponse.session
        ) {
          store.dispatch(setSession(sessionIdentificationResponse.session));
          if (
            existingSessionVerification !==
            sessionIdentificationResponse.session?.metaData?.isVerified
          ) {
            console.log("Contact Updated");
            // Contact ID has been changed or verification status is changed
            CampaignsController.dismissAllCampaigns();
            sesSessionInitialData(store, sessionIdentificationResponse as any);
            NotifyUnreadMessages(store);
            loadAllConversations(store, true);
            loadAllTickets(store, true);
          }
        }
      })
      .catch((e) => {
        console.log("Error while identifying user", e);
        if (
          e.response?.data?.unmatchedCustomerUserId ||
          e.response?.data?.message.includes("Primary identifiers do not match")
        ) {
          SetLocalStorageItemSafely(
            `${widgetId}-last-user-id`,
            JSON.stringify({
              timestamp: Date.now(),
              data: userData,
            })
          );
          ResetSessionAndReload(store);
        }
      });
  }
};

export class ParentWindowService {
  static currentPageDetails: {
    title: string;
    url: string;
  };
  static get window() {
    return this.PARENT;
  }

  static isWidgetMinimized = false;

  static PARENT = window.parent;

  static init({ WIDGET_ID }: { WIDGET_ID: string }) {
    const CC_EVENT_METHOD =
      typeof window.addEventListener !== "undefined"
        ? "addEventListener"
        : "attachEvent";
    const CC_EVENT_LISTENER = (window as any)[CC_EVENT_METHOD];
    const CC_EVENT_TYPE =
      CC_EVENT_METHOD === "attachEvent" ? "onmessage" : "message";

    const eventProcessor = (event: WindowEvent) => {
      const Event: CCEvent = stringToJson(event.data);
      // console.log("Event Client: ", event.data,Event);
      switch (Event.type) {
        case "PRE_INIT_CONFIG": {
          const config = Event.data as iPreInitConfig;
          store.dispatch(setPreInitConfig(config));
          break;
        }
        case "INIT": {
          // console.log('Event: Init the widget');
          break;
        }
        case "SET_WIDGET_CONFIG": {
          const { widget } = Event.data as {
            widget: iWidget;
          };
          store.dispatch(setWidget(widget));
          break;
        }
        case "RESET_SESSION_AND_RELOAD": {
          // console.log('Event: Init the widget');
          ResetSessionAndReload(store);
          break;
        }
        case "CLIENT_INFO": {
          const session = selectSession(store.getState());
          const { name, email, phone } = Event.data as {
            name: string;
            email: string;
            phone: string;
          };
          const fieldsToUpdate: {
            firstName?: string;
            lastName?: string;
            primaryEmail?: string;
            primaryMobile?: string;
          } = {};
          if (!session?.contact?.data.firstName && name) {
            const [firstName, ...otherNames] = name.split(" ");
            fieldsToUpdate.firstName = firstName;
            fieldsToUpdate.lastName = otherNames.join(" ");
          }
          if (!session?.contact?.data.primaryEmail && email) {
            fieldsToUpdate.primaryEmail = email;
          }
          if (!session?.contact?.data.primaryMobile && phone) {
            fieldsToUpdate.primaryMobile = phone;
          }
          if (Object.keys(fieldsToUpdate).length > 0) {
            SDK.updateContactDetails(fieldsToUpdate)
              .then((d) => {
                store.dispatch(setSession(d));
              })
              .catch((e) => {
                // Show Error
              });
          }

          break;
        }
        case "SET_SUBJECT_LINE": {
          // console.log("Event: SET_SUBJECT_LINE");
          const data = Event.data as {
            subject: string;
          };
          store.dispatch(setPreferredSubjectLine(data.subject));
          break;
        }

        case "IDENTIFY_USER": {
          const session = selectSession(store.getState());
          const widget = selectWidget(store.getState());
          const userData = Event.data as {
            id?: string;
            firstName?: string;
            lastName?: string;
            primaryEmail?: string;
            primaryMobile?: string;
            company?: {
              id?: string;
              name: string;
            };
          };

          identifyUser(userData, widget?.id || WIDGET_ID, session);

          break;
        }

        case "CURRENT_PAGE_DETAILS": {
          // console.log("Event: CURRENT_PAGE_DETAILS", Event);

          const { url, title, visitTimestamp, referer } = Event.data as {
            url: string;
            title: string;
            visitTimestamp: number;
            referer: string;
          };
          ParentWindowService.currentPageDetails = {
            title: title,
            url: url,
          };

          CampaignsController.addEvent({
            url,
            title,
            visitTimestamp: Date.now(),
            referer,
          });
          // Todo: Call Socket and send details
          SDK.sendPageHitActivity({
            id: SocketConnector.uuid,
            landedPage: {
              title: title,
              url: url,
            },
            referer: referer,
            startedTime: visitTimestamp,
            endedTime: 0,
          }).catch((e) => {
            console.log("Error while logging activity", e);
          });
          break;
        }

        case "TRIGGER_INVITE": {
          const { messages } = Event.data as {
            messages: iLocalConversationMessage[];
          };
          initLocalConversation(store, { messages, notifyUsers: true });
          break;
        }
        case "START_CHAT_WITH_CAMPAIGN": {
          // console.log("Event: START_CHAT_WITH_CAMPAIGN", Event);
          const runAsync = async () => {
            const { userId, message, chatBotId } = Event.data as {
              userId: string;
              message: string;
              chatBotId: string;
            };
            await loadAllConversations(store);
            CampaignsController.dismissAllCampaigns();
            const conversations = selectConversationsQuery("min")(
              store.getState()
            ).list;
            const openConversation = find(conversations, { status: "OPEN" });

            if (chatBotId) {
              if (!openConversation) {
                CampaignsController.triggerChatbotAsCampaign(chatBotId);
              }
            } else if (message) {
              const inviteCampaign = GenerateSimpleCampaign({
                id: "CAMPAIGN_FROM_PARENT",
                text: message,
                quickReplies: undefined,
                coverImage: undefined,
                options: {
                  userId: userId,
                },
              });

              if (!openConversation) {
                CampaignsController.dismissAllCampaigns();
                CampaignsController.triggerCampaign(inviteCampaign, {
                  isTemporary: true,
                  muteNotifications: true,
                });
              }
            }
          };
          runAsync();

          break;
        }
        case "DISMISS_CAMPAIGN": {
          // console.log("Event: DISMISS_CAMPAIGN", Event);
          CampaignsController.dismissAllCampaigns();
          break;
        }
        case "NAVIGATE_TO_CONVERSATION_VIEW": {
          console.log("NAVIGATE_TO_CONVERSATION_VIEW");
          const { conversationId } = Event.data as {
            conversationId: string;
          };
          store.dispatch(push(`/conversations/${conversationId}`));
          setTimeout(() => {
            store.dispatch(push(`/conversations/${conversationId}`));
          }, 400);
          setTimeout(() => {
            store.dispatch(push(`/conversations/${conversationId}`));
          }, 1000);
          break;
        }

        case "NAVIGATE_TO_HOME_VIEW": {
          store.dispatch(push(`/`));
          break;
        }
        case "NAVIGATE_TO_ARTICLE": {
          const { articleId } = Event.data as { articleId: string };

          store.dispatch(push(`/knowledge-base/articles/${articleId}`));
          this.sendMessage({ type: "OPEN_WIDGET" });
          // const isMaximizedAtLeastOnce = selectWidgetMaximizedAtLeastOnce(
          //   store.getState()
          // );
          // if (isMaximizedAtLeastOnce) {
          //   store.dispatch(push(`/knowledge-base/articles/${articleId}`));
          // } else {
          //   setTimeout(() => {
          //     store.dispatch(push(`/knowledge-base/articles/${articleId}`));
          //   }, 400);
          // }
          break;
        }
        case "REFERER_DETAILS": {
          // console.log("Event: REFERER_DETAILS", Event);
          // this.socketService.socket.emit("REFERER_DETAILS", Event.data);
          const { url } = Event.data as { url: string };
          if (url) {
            SDK.updateReferer({ url: url }).catch((e) => {
              console.log("Error while updating referer", e);
            });
          }
          break;
        }
        case "SNAPSHOT": {
          // console.log('Event: SNAPSHOT', Event);
          // this.socketService.socket.emit("SNAPSHOT", Event.data);
          break;
        }
        case "FRAME_VISIBILITY_CHANGED": {
          // console.log("Event: FRAME_VISIBILITY_CHANGED", Event);
          store.dispatch(setWidgetMaximizedState(Event.data.isMaximized));
          // Todo:check the above
          break;
        }
        case "EVENTS": {
          // console.log('Event: events', Event);
          break;
        }
        case "PREVIEW_WIDGET_CONFIGURATIONS": {
          console.log("Event Client: PREVIEW_WIDGET_CONFIGURATIONS", Event);
          const widget: iWidget = Event.data.widget;

          store.dispatch(patchWidget(widget));
          break;
        }
        case "CHANGE_CC_COLOR": {
          // console.log("Event Client: CHANGE_CC_COLOR", Event);
          const { color } = Event.data;

          store.dispatch(patchWidgetColor(color));
          break;
        }
        case "CALENDLY_SUBMITTED": {
          console.log("Event Client: CALENDLY_SUBMITTED", Event);
          const data = Event.data;
          SDK.addActivity({ type: "CALENDLY_EVENT_SCHEDULED", data }).catch(
            (e) => {
              console.log("Error while adding calendly event", e);
            }
          );
          break;
        }
        case "FORM_SUBMITTED": {
          console.log("Event Client: FORM_SUBMITTED", Event);
          const data = Event.data;
          const widget = selectWidget(store.getState());
          if (!widget?.configurations?.disableFormTracking) {
            SDK.addActivity({ type: "FORM_SUBMITTED", data }).catch((e) => {
              console.log("Error while adding form submission event", e);
            });
          }

          break;
        }
        case "CREATE_TICKET": {
          console.log("Event Client: CREATE_TICKET", Event);
          const data = Event.data;
          const contactData = extractContactDataFromDataObj(data);
          SDK.createNewTicket({
            ticketData: data,
            contactData: contactData,
          }).catch((e) => {
            console.log("Error while adding form submission event", e);
          });
          break;
        }
        case "LOG_ACTIVITY": {
          console.log("Event Client: LOG_ACTIVITY", Event);
          const data = Event.data;
          SDK.addActivity({ type: data.type || "CUSTOM_EVENT", data }).catch(
            (e) => {
              console.log("Error while adding calendly event", e);
            }
          );
          break;
        }
        case "RE_REQUEST_SESSION_VERIFICATION": {
          const session = selectSession(store.getState());
          const isVerified = session?.metaData?.isVerified;
          const sessionToken = SocketConnector.lastToken;
          ParentWindowService.sendMessage({
            type: "SESSION_VERIFICATION_UPDATED",
            data: {
              isVerified,
              sessionToken,
              contactId: session?.contactId,
            },
          });
          break;
        }
        case "SHOW_ACTIVE_CHECKLIST": {
          GlobalEventEmitter.emit("SHOW_ACTIVE_CHECKLIST");
          const allCheckLists = selectActivatedCheckLists(store.getState());
          const activeCheckList = (allCheckLists || []).find(
            (e) => !e.isFullyCompleted
          );
          const isMaximized = selectWidgetMaximizedState(store.getState());
          if (activeCheckList && isMaximized) {
            store.dispatch(push(`/checklists/${activeCheckList.id}`));
          }

          break;
        }
        case "ACTIVATE_CHECK_LIST": {
          const data = Event.data;
          const checkListId = data.checkListId;
          GlobalEventEmitter.emit("ACTIVATE_CHECK_LIST", { checkListId });
          const allCheckLists = selectActivatedCheckLists(store.getState());
          const activeCheckList = (allCheckLists || []).find(
            (e) => e.checkListId === checkListId
          );
          const isMaximized = selectWidgetMaximizedState(store.getState());
          if (activeCheckList && isMaximized) {
            store.dispatch(push(`/checklists/${activeCheckList.id}`));
          }

          break;
        }
        case "ADD_TO_SEQUENCE": {
          const data = Event.data;
          const { sequenceId, forceAdd } = data;
          SDK.addContactToSequence({ sequenceId, forceAdd }).catch((e) => {
            console.log("Error while adding customer to sequence", e);
          });

          break;
        }
        default:
          return;
      }
    };

    CC_EVENT_LISTENER(
      CC_EVENT_TYPE,
      (event) => {
        eventProcessor(event);
      },
      false
    );
  }

  static sendMessage(message: { type: string; data?: any }) {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(JSON.stringify(message), "*");
  }

  static dismissChatBubble() {
    this.sendMessage({
      type: "DISMISS_CHAT_BUBBLE",
      data: {},
    });
  }

  static async sendNewMessageNotification(
    message: iMessage,

    options: { muteNotification?: boolean; resetCount?: boolean } = {}
  ) {
    // console.log('sendNewMessageNotification', message)
    if (message.from?.senderType === "USER") {
      let organizationName = selectWidget(store.getState())?.configurations
        ?.locale?.custom?.TEAM_NAME;
      if (message.organizationId === "fc89e0") {
        organizationName = "Click Connector";
      }

      const coverImage = (() => {
        const coverImageAttachment = find(message.attachments, {
          type: MessageAttachmentTypes.GREETINGS_COVER,
        });

        if (coverImageAttachment) {
          return coverImageAttachment.payload.coverImage;
        }
      })();

      const widgetConfig = selectWidget(store.getState());

      const senderData = await (async () => {
        if (
          message.from.id === "cc" ||
          message.from.id === "CC" ||
          message.from.id === "SYSTEM"
        ) {
          return {
            userName:
              widgetConfig?.configurations?.chatBotProfile?.name ||
              "AI Assistant",
            userAvatar: widgetConfig?.configurations?.chatBotProfile?.avatar,
          };
        }

        const user = await (message.from.id
          ? getUserById(message.from.id, store)
          : getRandomUser(store));

        return {
          userName: user?.data?.firstName,
          userAvatar: user?.data?.avatar,
        };
      })();

      ParentWindowService.sendMessage({
        type: "CC_NEW_MESSAGE",
        data: {
          message: message.message,
          userName: senderData.userName,
          userAvatar: senderData.userAvatar,
          conversationId: message.conversationId,
          resetCount: options.resetCount,
          organizationName: organizationName,
          coverImage: coverImage,
          automaticallyOpenChatWidgetOnNewMessages:
            widgetConfig?.configurations
              ?.automaticallyOpenChatWidgetOnNewMessages,
        },
      });
      if (!options.muteNotification) {
        AudioNotificationsService.playAudio(AvailableAudios.NEW_MESSAGE);
      }
    }
  }

  static minimizeWidget() {
    this.sendMessage({
      type: "CLOSE_WIDGET",
      data: {},
    });
  }

  static maximizeWidget() {
    this.sendMessage({
      type: "OPEN_WIDGET",
      data: {},
    });
  }
}

export interface WindowEvent {
  data: any;
}

export interface CCEvent {
  type: string;
  data: any;
}
